let config={
    appName:'StoryRover',
    googlePlayUrl:'https://play.google.com/store/apps/details?id=com.gamefactory.idleinvestor',
    appStoreUrl:'https://apps.apple.com/us/app/id1538306973',
    contactEmail:'thisfuns.cs@outlook.com',
    copyright:'StoryRover',
    title:'Thisfun',
    logoName:'StoryRover',
    fbid:'1124673794769586',
    websiteUrl:'storyrover.icrazynovel.com'
}

export default config;
