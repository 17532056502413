import React from 'react'
import { Link } from 'react-router-dom'
import config from "@/utils/config";
import Style from './Footer.module.scss'
import classNames from 'classnames';
const Footer = () => {
    return (
        <div className={classNames('window-width pl45 pt34 pr45 sizing pb36',[Style.footer])}>
            <div className="top-link fz18 lh30 fw600 text-white flex items-center">
                <Link to="/privacyPolicy" className="text-white hover-primary">Privacy Policy</Link>
                <div className={[Style.line]}></div>
                <Link to="/termsAndConditions" className="text-white hover-primary">Terms and Condition</Link>
            </div>

            <div className={[Style['page-links']]}>
                <a href="https://www.facebook.com/" target={'_blank'} rel="noreferrer">
                    <img src={require('@/assets/images/facebook.png').default} alt="" />
                </a>
                <a href="https://www.instagram.com/" target={'_blank'} rel="noreferrer">
                    <img src={require('@/assets/images/instagram.png').default} alt="" />
                </a>
                <a href="https://www.linkedin.com/" target={'_blank'} rel="noreferrer">
                    <img src={require('@/assets/images/linkedin.png').default} alt="" />
                </a>
            </div>
            <div className={Style['copyright']}>
            Copyright © 2022 Thisfun All rights reserved.
            </div>
        </div>
    )
}
export default Footer;
